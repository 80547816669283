import React, { useEffect, useState } from 'react'
import { Clerk } from '@formclerk/react'

import SiteMetadata from '../queries/SiteMetadata'

const TEXT_AREA_COLUMNS_SMALL_SCREENS = 20
const TEXT_AREA_COLUMNS_LARGE_SCREENS = 50

const ContactForm = () => {
  const { clerkId } = SiteMetadata()
  const [textareaColumns, setTextareaColumns] = useState(
    TEXT_AREA_COLUMNS_SMALL_SCREENS
  )

  useEffect(() => {
    const adjustTextareaColumns = () => {
      const { innerWidth } = window

      if (innerWidth < 600) {
        if (textareaColumns !== TEXT_AREA_COLUMNS_SMALL_SCREENS) {
          setTextareaColumns(TEXT_AREA_COLUMNS_SMALL_SCREENS)
        }
      } else {
        if (textareaColumns !== TEXT_AREA_COLUMNS_LARGE_SCREENS) {
          setTextareaColumns(TEXT_AREA_COLUMNS_LARGE_SCREENS)
        }
      }
    }

    window.addEventListener('resize', adjustTextareaColumns)

    return window.removeEventListener('resize', adjustTextareaColumns)
  }, [textareaColumns])

  return (
    <>
      <h3>Kontakta oss</h3>
      <Clerk
        clerkID={clerkId}
        contentClassName='form'
        Initializing={() => <p>Laddar...</p>}
        Processing={() => <p>Skickar kontaktinformation, vänligen vänta...</p>}
        Success={() => (
          <p>
            Tack för att du har kontaktat oss! Vi hör av oss så snart vi kan.
          </p>
        )}
        Failure={() => (
          <p>
            Ajdå, något verkar ha blivit fel, du får gärna kontakta oss på annat
            vis.
          </p>
        )}
      >
        <label className='form__label'>
          Namn *{' '}
          <input
            type='text'
            name='namn'
            required={true}
            className='form__input'
          />
        </label>
        <label className='form__label'>
          Telefon *{' '}
          <input
            type='tel'
            name='telefon'
            required={true}
            className='form__input'
          />
        </label>
        <label className='form__label'>
          E-post *<input type='email' name='e-post' className='form__input' />
        </label>
        <label className='form__label'>
          Adress <input type='text' name='adress' className='form__input' />
        </label>
        <label className='form__label'>
          Ort *
          <input
            type='text'
            name='ort'
            required={true}
            className='form__input'
          />
        </label>
        <label className='form__label'>
          Postnummer{' '}
          <input type='text' name='postnummer' className='form__input' />
        </label>
        <label className='form__label form__label--full-width'>
          Ärende *{' '}
          <textarea
            name='ärende'
            required={true}
            className='form__input '
            rows='6'
            cols={textareaColumns}
          />
        </label>
        <input type='submit' className='form__submit' value='Skicka'></input>
      </Clerk>
    </>
  )
}

export default ContactForm
