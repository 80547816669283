import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import ContactForm from 'components/ContactForm'

const Card = ({ title, text }) => (
  <div>
    <h3>{title}</h3>
    <p>{text}</p>
  </div>
)

const IndexPage = ({ data }) => {
  const services = [
    {
      title: 'Elinstallationer',
      text: 'Det kan vara både nyinstallation och renovering av befintlig elanläggning.',
    },
    {
      title: 'Rådgivning',
      text: 'Vart placerar man belysningspunkterna för att få en trivsam ljusbild? Hur många uttag bör man ha i ett visst utrymme? Eller är det något annat inom el ni funderar på?',
    },
    {
      title: 'Felsökning',
      text: 'Har ni något på er elanläggning som inte fungerar som det ska? Låter konstigt? Eller luktar bränt?',
    },
    {
      title: 'Elbilsladdning',
      text: 'Funderar ni på att köpa en elbil eller har ni precis införskaffat er en? Då vill ni självklart kunna ladda elbilen hemma på ett snabbt och säkert sätt.',
    },
    {
      title: 'Smarta hem',
      text: 'Förenkla vardagen genom smarta belysnings system som låter dig till exempel styra ditt hem direkt i telefonen.',
    },
  ]
  return (
    <Layout pageTitle='Hem'>
      <oma-grid-row>
        <section className='article__content page__column'>
          <h1 className='article__heading article__heading--large'>
            Säkra elinstallationer och smidigare vardag
          </h1>
          <p>
            Hagby elektriska AB utgår från Åkersberga och utför elinstallationer
            för en säkrare och smidigare vardag, så väl hemma som på din
            arbetsplats.
          </p>
          <p>
            Jag som bildade Hagby elektriska AB heter Richard Forsell och har
            varit verksam som elektriker sedan 2006. Det bästa enligt mig med
            det här yrket är att få vara med på hela resan från planering och
            projektering till sista pusselbiten när man trycker på strömbrytaren
            och lampan tänds.
          </p>
          <GatsbyImage
            image={data.facade.childImageSharp.gatsbyImageData}
            alt=''
          />
        </section>
      </oma-grid-row>
      <oma-grid-row>
        <section className='article__content page__column page__column--divided'>
          <div>
            <h2 className='article__heading article__heading--medium'>
              Hagby elektriska AB utför
            </h2>

            {services.map((service, index) => (
              <Card key={index} title={service.title} text={service.text} />
            ))}
          </div>

          <GatsbyImage
            image={data.lightButtons.childImageSharp.gatsbyImageData}
            alt=''
          />
        </section>
      </oma-grid-row>
      <oma-grid-row>
        <section className='article__content page__column page__column--divided'>
          <h2 className='page__column-item--full-width'>
            Du är själv ansvarig för elen i din bostad
          </h2>

          <GatsbyImage
            image={data.unfinishedWork.childImageSharp.gatsbyImageData}
            alt=''
          />

          <div>
            <p>
              Det är du som äger bostaden du bor i som är elanläggningsansvarig.
              Det innebär att du själv är ansvarig för underhåll och kontroll av
              elen i din bostad. Vill du veta mer om detta, gå gärna in på{' '}
              <a
                className='link'
                target='_blank'
                rel='noreferrer'
                href='https://www.elsakerhetsverket.se/privatpersoner/din-elanlaggning/ansvaret-for-elanlaggningen/'
                title='Ansvaret för elanläggningen | Elsäkerhetsverket (elsakerhetsverket.se)'
              >
                elsäkerhetsverket.se
              </a>
            </p>

            <h3>Att tänka på!</h3>
            <p>
              När ni har bestämt er för att ni vill göra om något i er bostad
              men är osäkra på om du som lekman får göra jobbet själv kan du gå
              in på{' '}
              <a
                className='link'
                target='_blank'
                rel='noreferrer'
                href='https://www.elsakerhetsverket.se/kopplasakert/'
                title='Kopplasäkert - En tjänst från
            Elsäkerhetsverket (elsakerhetsverket.se)'
              >
                kopplasäkert.se.
              </a>{' '}
              Här kan du enkelt söka på vad som får och inte får göras.
            </p>
            <p>
              Oavsett om ni väljer Hagby elektriska AB eller inte så råder vi er
              att alltid gå in på{' '}
              <a
                className='link'
                target='_blank'
                rel='noreferrer'
                href='https://e-tjanster.elsakerhetsverket.se/foretag/kolla-elforetaget'
                title='Kolla elföretaget (elsakerhetsverket.se)'
              >
                elsäkerhetsverket.se
              </a>{' '}
              och kolla att bolaget är registrerat hos dem.
            </p>
          </div>
        </section>
      </oma-grid-row>
      <oma-grid-row>
        <section className='article__content page__column'>
          <ContactForm />
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  query {
    facade: file(relativePath: { eq: "facade.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    lightButtons: file(relativePath: { eq: "light-buttons.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    unfinishedWork: file(relativePath: { eq: "unfinished-work.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: FULL_WIDTH
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`

export default IndexPage
